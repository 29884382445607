<template>
<div class="lds-animation"><div></div><div></div><div></div></div>
</template>

<script>
export default {
  name: "Spinner",
  data () {
    return {
    }
  },
};
</script>

<style lang="scss" scoped>
.lds-animation {
  display: inline-block;
  position: relative;
  width: 8rem; 
  height: 8rem; 
}
.lds-animation div {
  display: inline-block;
  position: absolute;
  top: 300px;
  left: .8rem;
  width: 1.6rem; // 1.6rem 
  background: #fff;
  animation: lds-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-animation div:nth-child(1) {
  left: .8rem; // .8rem
  animation-delay: -0.24s;
}
.lds-animation div:nth-child(2) {
  left: 3.2rem; // 3.2rem 
  animation-delay: -0.12s;
}
.lds-animation div:nth-child(3) {
  left: 5.6rem; // 5.6rem 
  animation-delay: 0;
}
@keyframes lds-animation {
  0% {
    top: 1.5rem; // .8rem
    height: 6.4rem; // 6.4rem
  }
  50%,
  100% {
    top: 2.4rem; // 2.4rem
    height: 3.2rem; // 3.2rem 
  }
}
</style>
