<template>
  <div>
    <div class="title-buttons-c">
      <div class="title-c">
        <h1 class="title title-general" data-scroll="fade-left">The Markets</h1>
        <p class="text" data-scroll="fade-left">Our clients are in every region of the world and cut across many industries, sectors and governance structures. What unites them all is their desire for a more effective approach to solving problems.</p>
        <!-- <button class="button" data-scroll="fade-left">Learn more</button> -->
        <router-link class="button" data-scroll="fade-left" :to="{ name: 'markets' }">Learn more</router-link>
      </div>
      <!--
      //# Buttons -->
      <ul class="buttons-list">
        <!-- prettier-ignore -->
        <li
          v-for="category in categories"
          :key="category.id"
          class="buttons-item"
          data-scroll="fade-up"
          data-scroll-delay="1"
        >
          <button @click="changeCategory(category.id)" class="btn-circular" :class="{active: category.active}">
            <span class="buttons-name">{{ category.name }}</span>
          </button>
        </li>
      </ul>
    </div>

    <!-- Results -->
    <div class="results" data-scroll="fade-up">
      <div class="results-list">
        <!-- prettier-ignore -->
        <div
          v-for="item in activeCategory.content"
          :key="item.id"
          class="results-item"
          :style="`height:${item.percentage}%;`"
        >
          <h3 class="text-c">
            <div class="first" v-html="item.first"></div>
            <AnimatedNumber class="percentage" :number="item.percentage" />
            <div class="second" v-html="item.second"></div>
          </h3>
          <div class="line"></div>
        </div>
      </div>
      <div class="results-background" :style="backgroundPositions"></div>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from '@/components/AnimatedNumber';
export default {
  name: 'ContactSwiper',
  components: {
    AnimatedNumber,
  },
  data() {
    return {
      categories: [
        {
          name: 'Asia',
          id: 0,
          active: true,
          content: [
            {
              id: 0,
              first: '<strong>Consumer<br/> Products Clients</strong>Including',
              percentage: 27,
              second: 'in the<br/> Fortune 500',
            },
            {
              id: 1,
              first: '<strong>Financial Services Clients <br/></strong> of which',
              percentage: 68,
              second: 'for publicly-traded <br/> companies',
            },
            {
              id: 2,
              first: '<strong>Professional<br/> Services Clients</strong>of which',
              percentage: 50,
              second: 'for management<br/> consulting firms',
            },
          ],
        },
        {
          name: 'Americas',
          id: 1,
          active: false,
          content: [
            {
              id: 0,
              first: '<strong>Consumer<br/> Products Clients</strong>Including',
              percentage: 70,
              second: 'in the<br/> Fortune 500',
            },
            {
              id: 1,
              first: '<strong>Agribusiness &<br/> Commodities Clients</strong>of which',
              percentage: 78,
              second: 'for family-led<br/> companies',
            },
            {
              id: 2,
              first: '<strong>Professional<br/> Services Clients</strong>of which',
              percentage: 68,
              second: 'advertising & marketing <br/> services agencies',
            },
          ],
        },
        {
          name: 'Emea',
          id: 2,
          active: false,
          content: [
            {
              id: 0,
              first: '<strong>Consumer<br/> Products Clients</strong>Including',
              percentage: 38,
              second: 'FTSE 100',
            },
            {
              id: 1,
              first: '<strong>Agribusiness &<br/> Commodities Clients</strong>of which',
              percentage: 40,
              second: 'for family-led<br/> companies',
            },
            {
              id: 2,
              first: '<strong>Professional<br/> Services Clients</strong>of which',
              percentage: 80,
              second: 'for management<br/> consulting firms',
            },
          ],
        },
      ],
      // End categories
    };
  },
  computed: {
    activeCategory() {
      return this.categories.find((category) => category.active);
    },
    position1() {
      return `var(--position1-x) ${100 - this.activeCategory.content[0].percentage}%`;
    },
    position2() {
      return `var(--position2-x) ${100 - this.activeCategory.content[1].percentage}%`;
    },
    position3() {
      return `var(--position3-x) ${100 - this.activeCategory.content[2].percentage}%`;
    },
    backgroundPositions() {
      return `clip-path: polygon( 0 100%, ${this.position1}, ${this.position2}, ${this.position3}, 100% 100%)`;
    },
  },
  methods: {
    changeCategory(id) {
      this.categories.forEach((category) => {
        category.id == id ? (category.active = true) : (category.active = false);
      });
    },
  },
};
</script>
