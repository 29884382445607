<template>
	<!-- ASD -->
	<div>
		<div class="tab-menu">
			<!-- prettier-ignore -->
			<button data-scroll="fade-left"
        v-for="item in content" :key="`button${item.id}`"
        ref="buttons"
        class="btn-circular"
        @click="slideTo(item.id)"
      >
        <span class="buttons-name">{{ item.title }}</span>
      </button>
		</div>
		<swiper ref="swiperEl" :options="options" data-scroll="fade-right">
			<swiper-slide v-for="item in content" :key="item.id">
				<div class="c-card has-text-right">
					<h1 class="title">{{ item.title }}</h1>
					<p class="text" v-html="item.text"></p>
					<button
						class="button btn-small is-white is-rounded"
						@click="item.click"
					>
						{{ item.button }}
					</button>
				</div>
			</swiper-slide>
		</swiper>
	</div>
</template>

<script>
export default {
	name: "ContactSwiper",
	data() {
		return {
			options: {
				effect: "fade",
				slidesPerView: 1,
				loop: false,
				autoHeight: false,
				allowTouchMove: false,
				pagination: {
					el: ".swiper-pagination",
				},
			},
			content: [
				{
					id: 0,
					title: "Clients",
					text:
						"Ascentador partners are more likely to be in the market and on the move than sitting in the office. <b>Want to reach us?</b>",
					button: "Reach a Partner",
					click: () => {
						this.$emit("open-clients-modal");
					},
				},
				{
					id: 1,
					title: "Candidates",
					text:
						"Many of our clients rely on Ascentador to assist them in recruiting senior, executive talent as part of the solution set. Want to be considered as a candidate for these executive roles?",
					button: "Upload a Profile",
					click: () => {
						this.$emit("open-candidates-modal");
					},
				},
			],
		};
	},
	methods: {
		slideTo(id) {
			this.$refs.swiperEl.$swiper.slideTo(id);
			this.$refs.buttons.forEach((button) => {
				button.classList.remove("active");
			});
			this.$refs.buttons[id].classList.add("active");
		},
	},
	mounted() {
		this.slideTo(0);
	},
};
</script>
