import { render, staticRenderFns } from "./ExchangeModal.vue?vue&type=template&id=48782f6e&scoped=true&"
import script from "./ExchangeModal.vue?vue&type=script&lang=js&"
export * from "./ExchangeModal.vue?vue&type=script&lang=js&"
import style0 from "./ExchangeModal.vue?vue&type=style&index=0&id=48782f6e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48782f6e",
  null
  
)

export default component.exports