<template>
	<section>
		<div class="presentation" data-scroll="fade-opacity">
			<div
				class="animation"
				v-for="(animation, index) in animations"
				:key="index"
			>
				<h2
					class="animation-text title"
					:class="{ animate: animation.playing }"
					v-html="animation.text"
				></h2>
				<!-- prettier-ignore -->
				<lottie
        class="animation-lottie"
        :options="animation.options"
        v-on:animCreated="addToLotties"
      />
			</div>
		</div>
		<figure
			v-show="runButton"
			class="presentation__play"
			:class="pause && 'presentation__play-active'"
			v-on:click="animate"
		></figure>
	</section>
</template>

<script>
import Lottie from "vue-lottie";
import * as animation01 from "@/assets/home/hero/01.json";
import * as animation02 from "@/assets/home/hero/02.json";
import * as animation03 from "@/assets/home/hero/03.json";
import * as animation04 from "@/assets/home/hero/04.json";
import * as animation05 from "@/assets/home/hero/05.json";
import * as animation06 from "@/assets/home/hero/06.json";
import * as animation07 from "@/assets/home/hero/07.json";
import * as animation08 from "@/assets/home/hero/08.json";
import * as animation09 from "@/assets/home/hero/09.json";

export default {
	name: "HeroPresentation",
	components: {
		lottie: Lottie,
	},
	data() {
		return {
			itemActive: 0,
			pause: null,
			runButton: null,
			isComplete: false,
			duration: 4060,
			lotties: [],
			animations: [
				{
					number: "01",
					playing: false,
					text: "We are <span>Ascentador</span>",
					options: {
						animationSpeed: 2,
						animationData: animation01.default,
						loop: false,
						autoplay: false,
					},
				},
				{
					number: "02",
					playing: false,
					text: "We are <span>problem solvers</span>",
					options: {
						animationSpeed: 2,
						animationData: animation02.default,
						loop: false,
						autoplay: false,
					},
				},
				{
					number: "03",
					playing: false,
					text:
						'focused on <span class="small">helping companies</span> <span>optimize</span>',
					options: {
						animationSpeed: 2,
						animationData: animation03.default,
						loop: false,
						autoplay: false,
					},
				},
				{
					number: "04",
					playing: false,
					text: "their most <span>valuable asset...</span>",
					options: {
						animationSpeed: 2,
						animationData: animation04.default,
						loop: false,
						autoplay: false,
					},
				},
				{
					number: "05",
					playing: false,
					text: "<span>Talent</span>",
					options: {
						animationSpeed: 2,
						animationData: animation05.default,
						loop: false,
						autoplay: false,
					},
				},
				{
					number: "06",
					playing: false,
					text: "When we see companies <span>succeeding</span>",
					options: {
						animationSpeed: 2,
						animationData: animation06.default,
						loop: false,
						autoplay: false,
					},
				},
				{
					number: "07",
					playing: false,
					text: "executives <span>advancing</span>",
					options: {
						animationSpeed: 2,
						animationData: animation07.default,
						loop: false,
						autoplay: false,
					},
				},
				{
					number: "08",
					playing: false,
					text: "or innovation <span>increasing</span>",
					options: {
						animationSpeed: 2,
						animationData: animation08.default,
						loop: false,
						autoplay: false,
					},
				},
				{
					number: "09",
					playing: false,
					text: "we see <span>Talent</span><span>on the rise</span>",
					options: {
						animationSpeed: 2,
						animationData: animation09.default,
						loop: false,
						autoplay: false,
					},
				},
			],
		};
	},
	methods: {
		addToLotties(lottie) {
			this.lotties.push(lottie);
		},
		animate: function() {
			// for (let i = 0; i < this.animations.length; i++) {
			// this.timeout = setTimeout(() => {
			this.pause = false;
			localStorage.setItem("wowfactorstatus", JSON.stringify(false));
			this.animations[this.itemActive].playing = true;
			// Lotties
			this.lotties[this.itemActive].play();
			this.lotties[this.itemActive].setSpeed(1.4);
			this.lotties[this.itemActive].onComplete = () => {
				this.animations[this.itemActive].playing = false;
				this.lotties[this.itemActive].stop();
				this.itemActive++;
				if (this.itemActive === this.animations.length) {
					this.itemActive = 0;
					this.animations[this.itemActive].playing = true;
					// Lotties
					this.lotties[this.itemActive].play();
					this.lotties[this.itemActive].onEnterFrame = (event) => {
						if (event.currentTime >= 50) {
							this.lotties[this.itemActive].onEnterFrame = null;
							this.lotties[this.itemActive].pause();
							this.pause = true;
							localStorage.setItem("wowfactorstatus", JSON.stringify(true));
						}
					};
				} else {
					this.animate();
				}
			};
			//
			// }, this.duration * i);
			//}
		},
	},
	mounted() {
		this.runButton = true;
		if (JSON.parse(localStorage.getItem("wowfactorstatus")) !== true) {
			this.animate();
		} else {
			this.animations[0].playing = true;
			this.lotties[0].play();
			this.lotties[this.itemActive].onEnterFrame = (event) => {
				if (event.currentTime >= 50) {
					this.lotties[this.itemActive].onEnterFrame = null;
					this.lotties[this.itemActive].pause();
					this.pause = true;
				}
			};
		}
	},
};
</script>

<style lang="scss" scoped>
.presentation {
	position: relative;
	height: 60rem;
	width: 100%;
	&__play {
		height: 7rem;
		width: 7rem;
		background: url(../../assets/home/hero/player.svg) no-repeat center;
		z-index: 1000;
		position: absolute;
		bottom: 13rem;
		left: 27rem;
		opacity: 0;
		cursor: none;
		transition: opacity 1.5s ease;
		&-active {
			cursor: pointer;
			opacity: 1;
		}
		@media (max-width: 980px) {
			height: 6.5rem;
			width: 6.5rem;
			bottom: 0;
			left: 13rem;
		}
		@media (max-width: 360px) {
			top: 11rem;
			height: 5rem;
			width: 5rem;
		}
	}
	@media (max-width: 980px) {
		height: 46rem;
	}
	&__active {
		opacity: 1;
	}
}
.animation {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	&-text {
		width: calc(100% - 50rem);
		$offset: -40;
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: right;
		transform: translate3d(#{$offset - 5}rem, -50%, 0);
		opacity: 0;
		transition: opacity 1s ease, transform 2s ease;
		@media (max-width: 980px) {
			width: 100vw !important;
			text-align: left !important;
			top: 0;
			left: 3rem;
			transform: translate3d(-5rem, 0, 0);
		}
		&.animate {
			transform: translate3d(#{$offset}rem, -50%, 0);
			opacity: 1;
			transition-delay: 0.5s;
			@media (max-width: 980px) {
				transform: translate3d(0, 0, 0);
			}
		}
	}
	&-lottie {
		width: 60rem !important;
		height: 60rem !important;
		margin: 0 -7rem 0 auto !important;
		@media (max-width: 980px) {
			max-width: 40rem;
			width: 100% !important;
			height: auto !important;
			margin: 0 auto !important;
		}
	}
}
</style>
