<template>
  <div id="exchangeSwiper" data-scroll="fade-up">
    <swiper :options="options">
      <swiper-slide v-for="item in articles" :key="item.slug">
        <div class="c-card">
          <figure class="card-image"><img alt="exchange" :src="item.image" /></figure>
          <div class="card-content">
            <h4 class="title-item">{{ item.title }}</h4>
            <p class="text">{{ item.content }}</p>
            <router-link class="button btn-small" :to="{ name: `${item.type ==='classic' ? `exchange.${item.slug}`: `questionnaire.${item.slug}` }` }">Read the Article</router-link>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-button-c">
      <div class="swiper-button-next" slot="button-prev"></div>
      <div class="swiper-button-prev" slot="button-next"></div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default {
  name: 'ExchangeSwiper',
  props: {
    articles: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      options: {
        slidesPerView: 4,
        loop: true,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1: {
            slidesPerView: 1.5,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
      },
    };
  },
};
</script>
