<template>
	<transition name="modal-fade">
		<div v-show="active" class="modal-bk">
			<div class="modal c-card">
				<header class="modal-header">
					<slot name="header">
						<button type="button" class="btn-close" @click="close"></button>
					</slot>
				</header>
				<section class="modal-body">
					<slot name="body">
						<form action="" @submit.prevent="onSubmit" class="form-c">
							<div class="field-c">
								<input
									class="field --input"
									placeholder="Name"
									v-model="contact.name"
								/>
								<label class="error-label" v-if="error.name">{{
									error.name[0]
								}}</label>
							</div>
							<div class="field-c">
								<input
									class="field --input"
									placeholder="Email address"
									v-model="contact.email"
								/>
								<label class="error-label" v-if="error.email">{{
									error.email[0]
								}}</label>
							</div>
							<div class="field-c">
								<textarea
									class="field --textarea"
									placeholder="Message"
									v-model="contact.message"
								></textarea>
								<label class="error-label" v-if="error.message">{{
									error.message[0]
								}}</label>
							</div>
							<div class="input-group">
								<div class="field-c">
									<label class="error-label" v-if="error.file">{{
										error.file[0]
									}}</label>
									<label for="file2" class="button btn-small btn-input --border"
										>Upload</label
									>
									<input
										id="file2"
										ref="file"
										type="file"
										value="Upload file"
									/>
								</div>
								<div class="field-c">
									<button
										class="button btn-small btn-input"
										type="submit"
										value="Submit"
										:disabled="hasSent"
									>
										Submit
									</button>
								</div>
							</div>
						</form>
					</slot>
				</section>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from "axios";

export default {
	name: "ContactClientsModal",
	data() {
		return {
			active: false,
			hasSent: false,
			contact: {
				name: "",
				email: "",
				message: "",
				file_path: "",
			},
			API: process.env.VUE_APP_API,
			error: {},
		};
	},
	mounted() {
		this.header = document.querySelector(".header");
	},
	methods: {
		open() {
			this.active = true;
			this.header.classList.add("hidden");
			this.$emit("stop-scrolling");
		},
		close() {
			this.active = false;
			this.header.classList.remove("hidden");
			this.$emit("keep-scrolling");
			this.error = {};
		},
		async onSubmit() {
			this.hasSent = true;
			let formData = new FormData();
			let uploadFile = this.$refs.file.files[0];
			formData.append("file", uploadFile);
			formData.append("folder", "contacts");
			try {
				const response = await axios.post(`${this.API}/store-file`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});
				this.contact.file_path = response.data.file_src;
				await axios.post(`${this.API}/contact`, this.contact).then((res) => {
					if ((res.status === 201) | 204) this.$emit("contact-was-send");
				});
				this.close();
			} catch (error) {
				if (error.response.status === 422) {
					this.error = error.response.data.errors;
				}
			} finally {
				this.hasSent = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-bk {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.82);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999;
}
.modal {
	padding: 2.2rem 3.4rem 3.2rem 3.4rem;
	max-width: 72rem;
	width: 100%;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
}
.modal-header {
	padding: 15px;
	margin-bottom: 1rem;
	position: relative;
}
.modal-body {
	position: relative;
}
.btn-close {
	width: 3.2rem;
	height: 3.2rem;
	// position: absolute;
	position: relative;
	float: right;
	border: none;
	background: url("../../assets/icon-close.svg") no-repeat center center / 100%
		auto;
	cursor: pointer;
	font-weight: bold;
	&::after {
		content: "";
		position: absolute;
		height: 1rem;
		transform: rotate(45deg);
		width: 1rem;
		background-color: #fc6b02;
		border: 1px solid #011e30;
		display: block;
		transform: translate(-50%, -50%) rotate(45deg);
		top: 50%;
		left: 50%;
		@media (max-width: 980px) {
			height: 0.5rem;
			width: 0.5rem;
		}
	}
}

// Transition
.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}
</style>
